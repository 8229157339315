export default {
    SET_COMMERCES(state, commerces) {
      state.commerces = commerces
    },
    UPDATE_COMMERCE(state, commerce) {
      const commerceIndex = state.commerces.findIndex((p) => p.id_commerce == commerce.id_commerce)
      Object.assign(state.commerces[commerceIndex], commerce)
    },
    SET_PENDINGTOREQUEST(state, requests) {
      state.pendingToRequest = requests
    }
  }
