import state from './moduleDirectPaymentState.js'
import mutations from './moduleDirectPaymentMutations.js'
import actions from './moduleDirectPaymentActions.js'
import getters from './moduleDirectPaymentGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
