import axios from "@/axios.js"

export default {
  getCommerces({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`/commerce/direct-payment${params}`)
      .then((response) => {
        if (!response.data.status)
          return reject(response.data.msg)

        commit('SET_COMMERCES', response.data.data)
        return resolve(response.data)
      })
      .catch(error => {reject(error)})
    })
  },
  addCommerceToDirectPayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/commerce/direct-payment", payload)
        .then((response) => {
          if (!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_COMMERCE', response.data.data)
          return resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCommerceDirectPayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/commerce/direct-payment", payload)
        .then((response) => {
          if (!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_COMMERCE', response.data.data)
          return resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeCommerceFromDirectPayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/commerce/direct-payment/${payload.id_commerce_direct_payment}`, { data: payload })
        .then((response) => {
          commit('UPDATE_COMMERCE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  getDirectPaymentPendingPayments({commit}) {
    return new Promise((resolve,reject) => {
      axios.get(`/digital-payment/direct-payment/request/pending`)
        .then(response => {
          if(!response.data.status)
            reject(response.data.msg)

          commit('SET_PENDINGTOREQUEST', response.data.data)

          resolve(response.data)
        })
        .catch(error => console.log(error))
    })
  },
  addDirectPaymentCashOutRequest(ctx, payload = null ) {
    return new Promise((resolve, reject) => {
      axios.post(`/digital-payment/direct-payment/request`, payload)
          .then(response => {
              if(!response.data.status)
                  reject(response.data.msg)

              resolve(response.data)
          })
          .catch(err => console.log(err))
    })
  },
}
